import React, { Component } from 'react'
import Reveal from 'react-reveal/Reveal'
import Fade from 'react-reveal/Fade'
import { CooperationIcon, SupportIcon, InnovationIcon, CarefullyIcon } from '../../api/constants/ConfigImage'

export default class ChooseUs extends Component {
    render() {
        var images = [CooperationIcon, SupportIcon, InnovationIcon, CarefullyIcon];
        var choosusDetail = []
        var choosusMoreDetail = this.props.moreDetail;
        if (this.props.detail != null) {
            this.props.detail.forEach(function (value, i) {
                choosusDetail.push(
                    <Reveal effect="fadeInDown" key={i} >
                        <div className="text-center">
                            <div key={i} className="col-md-6 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="300ms" data-wow-delay="0ms">
                                <div className="row chooseus" style={{ padding: '20px 0' }}>
                                    <div className="col-md-3 col-sm-6 col-xs-4 hidden-md hidden-lg" style={{ top: '40px'}}> 
                                        <img src={images[i]} alt="payonmarine" />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-4 hidden-sm hidden-xs" style={{ top: '40px'}}>
                                        <img src={images[i]} alt="payonmarine" />
                                    </div>
                                    <div className="col-md-9 col-sm-6 col-xs-8 text-middle-parent" style={{ lineHeight: '150px', padding: '0 10px' }}>
                                        <div className="text-center text-middle-child">
                                            <p className=""><b>{value}</b></p>
                                            <p className="text-justify">{choosusMoreDetail[i]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div key={i} className="col-md-4 col-sm-6 col-xs-6 wow fadeInUp" data-wow-duration="300ms" data-wow-delay="0ms">
                                <p className="text">{value}</p>
                            </div> */}
                        </div>
                    </Reveal >)
            })
        }

        return (
            <div id="chooseus" className="section-height" style={{ height: 'unset' }}>
                <div className="row">
                    <div className="text-center">
                        <h4 className="text-upper" style={{ paddingTop: 20 }}>{this.props.name}</h4>
                    </div>
                    <div className="row" style={{ paddingTop: 20 }}>
                        {choosusDetail}
                    </div>
                </div>
            </div>

        );
    }
}