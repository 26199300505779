import React, { Component } from 'react';
import Fade from 'react-reveal/Fade'
import { Button } from 'react-bootstrap'
import { Technical } from '../../api/constants/ConfigImage'

export default class WorkExperience extends Component {
    render() {
        var tagsDetail = [];
        if (this.props.detail != null) {
            this.props.detail.forEach(function (value, i) {
                tagsDetail.push(<p key={i} className="text-justify">{value}</p>)
            })
        }
        return (
            <div id="qualityproduct" className="section-height" style={{ backgroundColor: "#f7f7f7" }}>
                <div className="row text-center">
                    <Fade left>
                        <div className="col-sm-6">
                            <div className="panel-one text-middle-parent">
                                <div className="text-center text-middle-child">
                                    <h4 className="text-upper">{this.props.name}</h4>
                                    {tagsDetail}
                                    <br />
                                    {/* <div className="readmore-button" >
                                        <Button variant="outline-secondary"> {this.props.message.Button.MoreDetail} </Button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="col-sm-6 col-md-6 col-lg-6" style={{ paddingTop: 20 }}>
                            <div className="item quality-info hidden-md hidden-lg" style={{ backgroundImage: "url(" + Technical + ")", height: '250px' }}>
                            </div>
                            <div className="item quality-info hidden-sm hidden-xs" style={{ backgroundImage: "url(" + Technical + ")", height: '350px' }}>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
}