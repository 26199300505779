import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer id="footer" style={{backgroundColor: '#5c5c5c'}}>
                <div className="row col-sm-12 text-center">
                    <span style={{ fontSize: "14px"}}>©2020All rights reserved - Payon Marine Services Co., Ltd.</span>
            </div>
            </footer>
        );
    }
}