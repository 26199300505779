import React, { Component } from 'react';
import Fade from 'react-reveal/Fade'
import { BusinessMan } from '../../api/constants/ConfigImage'

export default class QualityProduct extends Component {
    render() {
        var tagsDetail = [];
        if (this.props.detail != null) {
            this.props.detail.forEach(function (value, i) {
                tagsDetail.push(<p key={i} className="text-justify" style={{ display: "table-cell", verticalAlign: "middle" }}>{value}</p>)
            })
        }
        return (
            <div id="qualityproduct" className="section-height" style={{ backgroundColor: "#f7f7f7" }}>
                <div className="row text-center ">
                    <Fade left>
                        <div className="col-sm-6 hidden-md hidden-lg">
                            <div className="panel-one">
                                <div className="text-center">
                                    <h4 className="text-upper" style={{ paddingTop: 20 }}>{this.props.name}</h4>
                                    {tagsDetail}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 hidden-sm hidden-xs" style={{ paddingTop: 20 }}>
                            <div className="item quality-info" style={{ backgroundImage: "url(" + BusinessMan + ")", height: '350px' }}>
                            </div>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="col-sm-6 hidden-sm hidden-xs">
                            <div className="panel-one text-middle-parent">
                                <div className="text-center text-middle-child">
                                    <h4 className="text-upper">{this.props.name}</h4>
                                    {tagsDetail}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 hidden-md hidden-lg" style={{ paddingTop: 20 }}>
                            <div className="item quality-info" style={{ backgroundImage: "url(" + BusinessMan + ")", height: '250px' }}>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
}