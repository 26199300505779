import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { HeadBG_Info } from '../../api/constants/ConfigImage'

export default class Info extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        var tagsDetail = [];
        if (this.props.detail != null) {
            this.props.detail.map((item, index) => {
                tagsDetail.push(<span className=" text-main" style={{ margin: 0, color: "#FFF" }} key={index}>{item}</span>)
            })
        }
        return (
            <div id="main">
                <div className="row head-info" style={{ backgroundImage: "url(" + HeadBG_Info + ")" }}>
                    <div className="col-sm-12 text-center image-box-overlay">
                        <Fade top>
                            <div className="carousel-content" style={{ padding: "5%" }}>
                                <div className="hidden-xs" style={{ display: 'inline-block', marginTop: '10%' }}>
                                    {tagsDetail}
                                </div>
                                <div className="hidden-sm hidden-md hidden-lg" style={{ display: 'inline-block', marginTop: '35%' }}>
                                    {tagsDetail}
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
                {/* <div className="hero flex-center" style={{ backgroundImage: "url(" + HeadBG_Info + ")" }}>
                    <div class="hero-message">
                        {tagsDetail}
                    </div>
                </div> */}
            </div>
        );
    }
}