//APP

//export const QRCode = process.env.PUBLIC_URL + '/images/qrcode.jpg'
export const LogoCompany = process.env.PUBLIC_URL + '/images/logoCompany.png'
export const Iso9001 = process.env.PUBLIC_URL + '/images/iso9001.png'
export const Iso14001 = process.env.PUBLIC_URL + '/images/iso14001.png'
export const Iso = process.env.PUBLIC_URL + '/images/iso.png'

//About Us
export const HeadBG_AboutUs = process.env.PUBLIC_URL + '/images/aboutus.jpg'
export const HeadBG_AboutUsTemp = process.env.PUBLIC_URL + '/images/aboutus-template.png'
export const HeadBG_AboutUsTemp_th = process.env.PUBLIC_URL + '/images/aboutus-template-th.png'
export const TickIcon = process.env.PUBLIC_URL + '/images/icon/tick-icon.png'
export const Human = process.env.PUBLIC_URL + '/images/icon/human.png'
export const President = process.env.PUBLIC_URL + '/images/management/president.jpg'
export const ManagingDirector = process.env.PUBLIC_URL + '/images/management/managing-director.jpg'
export const GeneralManager = process.env.PUBLIC_URL + '/images/management/general-manager.jpg'
export const BusinessDevelopmentManager = process.env.PUBLIC_URL + '/images/management/business-development-manager.jpg'
export const BusinessStrategyAdvisor = process.env.PUBLIC_URL + '/images/management/business-strategy-advisor.jpg'
export const Team = process.env.PUBLIC_URL + '/images/management/team.jpg'

//Home Section1_Info
export const HeadBG_Info = process.env.PUBLIC_URL +  '/images/header.jpg'

//Home Section2_DetailInfo
export const ServiceArea = process.env.PUBLIC_URL +  '/images/servicearea.png'

//Home Section3_Service
export const BilgeBoat = process.env.PUBLIC_URL +  '/images/icon/blige_boat.png'
export const CarTransfer = process.env.PUBLIC_URL +  '/images/icon/car_transfer.png'
export const Factory = process.env.PUBLIC_URL +  '/images/icon/factory.png'
export const BoatTransfer = process.env.PUBLIC_URL +  '/images/icon/transfer_boat.png'
export const TugBoat = process.env.PUBLIC_URL +  '/images/icon/tug_boat.png'

export const ServiceHeader = process.env.PUBLIC_URL +  '/images/services/service-header.png'
export const ServiceHeaderTh = process.env.PUBLIC_URL +  '/images/services/service-header-th.png'
export const ServiceBoatBilge = process.env.PUBLIC_URL +  '/images/services/serviceBoatBilge2.jpg'
export const ServiceBoatShuttle = process.env.PUBLIC_URL +  '/images/services/serviceBoatShuttle2.jpg'
export const ServiceBoatTug = process.env.PUBLIC_URL +  '/images/services/serviceBoatTug.png'
export const ServiceCar = process.env.PUBLIC_URL +  '/images/services/serviceCar.png'
export const ServiceVan = process.env.PUBLIC_URL +  '/images/services/serviceVan.png'
export const ServiceFactoryPlace = process.env.PUBLIC_URL +  '/images/services/serviceFactoryPlace.png'
export const ServiceFactoryPlace3 = process.env.PUBLIC_URL +  '/images/services/serviceFactoryPlace3.png'
export const ServiceFactoryPlace4 = process.env.PUBLIC_URL +  '/images/services/serviceFactoryPlace4.png'
export const ServiceFactoryPlace5 = process.env.PUBLIC_URL +  '/images/services/serviceFactoryPlace5.png'
export const ServiceFactoryPlace6 = process.env.PUBLIC_URL +  '/images/services/serviceFactoryPlace6.jpg'
export const ServiceFactoryPlace7 = process.env.PUBLIC_URL +  '/images/services/serviceFactoryPlace7.jpg'
export const ServiceFactoryPlace8 = process.env.PUBLIC_URL +  '/images/services/serviceFactoryPlace8.jpg'
export const ServiceFactoryBilge = process.env.PUBLIC_URL +  '/images/services/serviceFactoryBilge.png'
export const ServiceFactoryStep1 = process.env.PUBLIC_URL +  '/images/services/serviceFactoryStep1.png'
export const ServiceFactoryStep2 = process.env.PUBLIC_URL +  '/images/services/serviceFactoryStep2.png'

export const ServiceFactoryProcess = process.env.PUBLIC_URL +  '/images/services/factoryProcess.png'
export const ServiceFactoryProcessTh = process.env.PUBLIC_URL +  '/images/services/factoryProcessTh.png'
export const ServiceFactoryFlowchart = process.env.PUBLIC_URL +  '/images/services/flowchart.png'

export const ServiceFactoryHeader = process.env.PUBLIC_URL +  '/images/services/factory-header.png'
export const ServiceFactoryHeaderTh = process.env.PUBLIC_URL +  '/images/services/factory-header-th.png'

//Home Section3_Choose Us
export const CooperationIcon = process.env.PUBLIC_URL +  '/images/icon/cooperation.png'
export const SupportIcon = process.env.PUBLIC_URL +  '/images/icon/support.png'
export const InnovationIcon = process.env.PUBLIC_URL +  '/images/icon/innovation.png'
export const CarefullyIcon = process.env.PUBLIC_URL +  '/images/icon/carefully.png'

//HOme Section4_Quality Product
export const BusinessMan = process.env.PUBLIC_URL + '/images/businessman2.png'

//Home Section5_Our Client
export const LogoClientIRPC = process.env.PUBLIC_URL + '/images/logoClient/logo_irpc.png'
export const LogoClientMOL = process.env.PUBLIC_URL + '/images/logoClient/logo_mol.png'
export const LogoClientMUL = process.env.PUBLIC_URL + '/images/logoClient/logo_mul.png'
export const LogoClientNYK = process.env.PUBLIC_URL + '/images/logoClient/logo_nyk.png'
export const LogoClientPTT = process.env.PUBLIC_URL + '/images/logoClient/logo_ptt.png'
export const LogoClientSC = process.env.PUBLIC_URL + '/images/logoClient/logo_scgroup.png'
export const LogoClientTHO= process.env.PUBLIC_URL + '/images/logoClient/logo_thaioil.png'
export const LogoClientTHORESEN = process.env.PUBLIC_URL + '/images/logoClient/logo_thoresen.png'
export const LogoClientUNI = process.env.PUBLIC_URL + '/images/logoClient/logo_unithai.png'
export const LogoClientVan = process.env.PUBLIC_URL + '/images/logoClient/logo_van.jpg'
export const LogoClientDeme = process.env.PUBLIC_URL + '/images/logoClient/logo_deme.jpg'
export const LogoClientHeung = process.env.PUBLIC_URL + '/images/logoClient/logo_heung.jpg'
export const LogoClientOocl = process.env.PUBLIC_URL + '/images/logoClient/logo_oocl.jpg'
export const LogoClientLBH = process.env.PUBLIC_URL + '/images/logoClient/logo_lbh.jpg'

// WorkExperience
export const Technical = process.env.PUBLIC_URL + '/images/technical.png'

// EnvironmentCare
export const Example4 = process.env.PUBLIC_URL +  '/images/example3.png'

