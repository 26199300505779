import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { connect } from 'react-redux'
import { switchLanguage } from '../../actions/content'
import Menu from '../app/Menu'
import '../../css/timeline.css'
import '../../css/timeline2.css'
import {
    HeadBG_AboutUs, HeadBG_AboutUsTemp, HeadBG_AboutUsTemp_th, TickIcon, Iso, Human, Team,
    President, ManagingDirector, GeneralManager, BusinessDevelopmentManager, BusinessStrategyAdvisor
} from '../../api/constants/ConfigImage'
import FabToTop from '../app/FabToTop'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class AboutUs extends Component {
    render() {
        const content = this.props.content.content;
        const contentCertificate = content.page.AboutUs.Certificate;
        const contentManagement = content.page.AboutUs.Management;
        const contentRoadtrip = content.page.AboutUs.RoadTrip;
        const switchLanguage = this.props.switchLanguage;
        const managementTeam = [President, ManagingDirector, GeneralManager, BusinessDevelopmentManager, BusinessStrategyAdvisor]
        let stepForWeb = [];
        let stepForMobile = [];

        if (content.page.AboutUs.Detail != null) {
            content.page.AboutUs.Detail.forEach((value, i) => {
                stepForMobile.push(
                    <div className={value.PeriodClassName} key={i}>
                        <div className="shape">
                            <span>{value.Period}</span>
                            {/* <h3>{value.Period}</h3> */}
                        </div>
                        <p className="steps-description">{value.PeriodDetail.join(' ')}</p>
                    </div>
                )

                stepForWeb.push(
                    <div className="timeline" key={i}>
                        <div className="timeline-icon">
                            <span style={i == content.page.AboutUs.Detail.length - 1 ? { fontSize: "22px" } : {}}>{value.Period}</span>
                        </div>
                        <div className="timeline-content">
                            <h4 className="title"></h4>
                            <p className="description">{value.PeriodDetail}</p>
                        </div>
                        <div className="border"></div>
                    </div>
                )
            })
        }
        return (
            <div id="mainaboutus" >
                <Menu className="hidden-md hidden-lg" name={content.appName} shortName={content.appShortName} data={content.page.Menu} switchLanguage={switchLanguage} />
                <div id="aboutus">
                    <div className="row head-info-height head-info">
                        <LazyLoadImage
                            alt="about payon marine services"
                            effect="blur"
                            src={content.lang == "th" ? HeadBG_AboutUsTemp_th : HeadBG_AboutUsTemp} // use normal <img> attributes as props
                        />

                        {/* <img src={content.lang == "th" ? HeadBG_AboutUsTemp_th : HeadBG_AboutUsTemp} alt="payon marine services" loading="lazy" /> */}
                    </div>
                    <div className="row introduce">
                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
                        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 text-justify">
                            <div><p>{contentRoadtrip.Detail1.join(' ')}</p></div>
                            <div><p>{contentRoadtrip.Detail2.join(' ')}</p></div>
                        </div>
                    </div>
                    <div className="row introduce">
                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
                        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 text-justify">
                            <p>{content.page.AboutUs.Introduce.join(' ')}</p>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="container">
                            <div className="row row-step hidden-sm hidden-md hidden-lg">
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <div id="Steps" className="steps-section">
                                        <div className="steps-timeline">
                                            {stepForMobile}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  hidden-xs">
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <div className="main-timeline">
                                        {stepForWeb}
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row certificate">
                        <div className="col-xs-12 col-sm-1 col-md-1 text-center"></div>
                        <div className="col-xs-12 col-sm-10 col-md-10 text-center">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                                    <h2><p>{contentCertificate.Header}</p></h2>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                {
                                    contentCertificate.Detail.map((value, index) => {
                                        return <div key={index} className="col-xs-12 col-sm-3 col-md-3 col-lg-3 item-cer">
                                            <div><img src={index == contentCertificate.Detail.length - 1 ? Iso : TickIcon}></img></div>
                                            <h5><p>{value.No}</p></h5>
                                            <p>{value.CertName}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row team">
                        <img src={Team} alt="payonmarineservices" />
                    </div>
                    <div className="row management">
                        <div className="col-xs-12 col-sm-1 col-md-1 text-center"></div>
                        <div className="col-xs-12 col-sm-10 col-md-10 text-center">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                                    <h2><p>{contentManagement.Header}</p></h2>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                {
                                    contentManagement.Detail.map((value, index) => {
                                        if (index < 4)
                                            return <div key={index} className="col-xs-12 col-sm-6 col-md-3 col-lg-3" style={{ padding: '20px 10px' }}>
                                                <div className="user" style={{ backgroundImage: "url(" + managementTeam[index] + ")" }}></div>
                                                <h5><p>{value.Position}</p></h5>
                                                <p>{value.Name}</p>
                                            </div>
                                    })
                                }
                            </div>
                            {/* <div className="row">
                                <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
                                {
                                    contentManagement.Detail.map((value, index) => {
                                        if (index >= 3)
                                            return <div key={index} className="col-xs-12 col-sm-5 col-md-5 col-lg-5" style={{ padding: '20px 10px' }}>
                                                <div className="user" style={{ backgroundImage: "url(" + managementTeam[index] + ")" }}></div>
                                                <h5><p>{value.Position}</p></h5>
                                                <p>{value.Name}</p>
                                            </div>
                                    })
                                }
                            </div> */}
                        </div>
                    </div>
                </div>
                <FabToTop tooltip={content.Text.Button.ToTop}></FabToTop>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        content: state.content
    }
}

const mapDispatchToProps = dispatch => {
    return {
        switchLanguage: (lang) => {
            return dispatch(switchLanguage(lang))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs)