import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { connect } from 'react-redux'
import { switchLanguage } from '../../actions/content'
import Menu from '../app/Menu'
import FabToTop from '../app/FabToTop'
import {
    ServiceFactoryHeader,
    ServiceFactoryHeaderTh,
    ServiceFactoryPlace, ServiceFactoryPlace3, ServiceFactoryPlace4, ServiceFactoryPlace5,
    ServiceFactoryPlace6, ServiceFactoryPlace7, ServiceFactoryPlace8,
    ServiceFactoryStep1, ServiceFactoryStep2,
    ServiceFactoryBilge,
    ServiceFactoryFlowchart
} from '../../api/constants/ConfigImage'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class ServiceFactory extends Component {
    render() {
        const content = this.props.content.content;
        const contentService = this.props.content.content.page.Services.Detail;
        const serviceFactory = contentService.ServiceFactory;
        const ourPartner = contentService.OurPartners;
        const serviceFactoryImage = [ServiceFactoryStep1, ServiceFactoryStep2];
        const switchLanguage = this.props.switchLanguage;
        const factoryPanel = [];
        const factorySystemPanel = [];
        serviceFactoryImage.map(function (value, i) {
            factoryPanel.push(
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6" key={i}>
                    <h3 className="hidden-xs">{serviceFactory.Detail.FactoryStep[i]}</h3>
                    <h5 className="hidden-sm hidden-md hidden-lg">{serviceFactory.Detail.FactoryStep[i]}</h5>
                    <LazyLoadImage
                        alt="payon marine services factory"
                        effect="blur"
                        src={serviceFactoryImage[i]}
                        width="100%"
                    />
                    {/* <img src={serviceFactoryImage[i]} alt="payonmarineservices" style={{ width: '100%' }} /> */}
                </div>
            );
        })

        serviceFactory.Detail.FactorySystem.map((value, i) => {
            factorySystemPanel.push(
                <p key={i} className="text-left">{value}</p>
            )
        })

        return (
            <div >
                <Menu name={content.appName} data={content.page.Menu} switchLanguage={switchLanguage} />
                <div id="service-factory">
                    <div className="row head-info-height head-info">
                        <LazyLoadImage
                            alt="payon marine services factory"
                            effect="blur"
                            src={content.lang == "th" ? ServiceFactoryHeaderTh : ServiceFactoryHeader}
                        // width="100%"
                        />
                        {/* <img src={content.lang == "th" ? ServiceFactoryHeaderTh : ServiceFactoryHeader} alt="payonmarineservices" /> */}
                    </div>
                    <div className="row name">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-1"></div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 text-upper" style={{ lineHeight: 'normal' }}>
                            <p>{serviceFactory.Name}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-2"></div>
                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-8 text-justify" style={{ lineHeight: 'normal' }}>
                            <p className="hidden-xs hidden-sm" style={{ fontSize: '20px' }}>{serviceFactory.Detail.MoreDetail}</p>
                            <p className="hidden-md hidden-lg">{serviceFactory.Detail.MoreDetail}</p>
                        </div>
                    </div>
                    <div className="row factory-moredetail">
                        <div className="col-md-1 col-lg-1 hidden-xs hidden-sm"></div>
                        <div className="col-xs-12 col-sm-12 col-md-11 col-lg-10">
                            <div className="row">
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                    <img src={ServiceFactoryPlace7} alt="payonmarineservices" />
                                </div>
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                    <img src={ServiceFactoryPlace6} alt="payonmarineservices" />
                                </div>
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                    <img src={ServiceFactoryPlace8} alt="payonmarineservices" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row factory-system">
                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 hidden-xs"></div>
                        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <div className="panel-one text-middle-parent" >
                                <div className="text-center text-middle-child" >
                                    {factorySystemPanel}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <img src={ServiceFactoryBilge} alt="payonmarineservices" style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="row factory-panel">
                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 hidden-xs"></div>
                        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                            <div className="row">
                                {factoryPanel}
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 hidden-xs"></div>
                    </div> */}
                    <div className="row factory-well">
                        <div className="col-md-1 col-lg-1 hidden-xs hidden-sm"></div>
                        <div className="col-xs-12 col-sm-12 col-md-11 col-lg-10">
                            <div className="row">
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-5 factory-well-left">
                                    <LazyLoadImage
                                        alt="payon marine services factory"
                                        effect="blur"
                                        src={ServiceFactoryPlace3}
                                    // width="100%"
                                    />
                                    {/* <img src={ServiceFactoryPlace3} alt="payonmarineservices" /> */}
                                </div>
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-2 factory-well-detail">
                                    <div className="panel-one text-middle-parent" >
                                        <div className="text-center text-middle-child" >
                                            <p> {serviceFactory.Detail.FactoryWells[0]}</p>
                                            <div className="hidden-xs"><i className="fa fa-long-arrow-right" style={{ fontSize: '80px' }} aria-hidden="true"></i></div>
                                            <div className="hidden-sm hidden-md hidden-lg"><i className="fa fa-long-arrow-down" style={{ fontSize: '80px' }} aria-hidden="true"></i></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 factory-well-right">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <LazyLoadImage
                                                alt="payon marine services factory"
                                                effect="blur"
                                                src={ServiceFactoryPlace4}
                                            // width="100%"
                                            />
                                            {/* <img src={ServiceFactoryPlace4} alt="payonmarineservices" /> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <LazyLoadImage
                                                alt="payon marine services factory"
                                                effect="blur"
                                                src={ServiceFactoryPlace5}
                                            // width="100%"
                                            />
                                            {/* <img src={ServiceFactoryPlace5} alt="payonmarineservices" /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ height: '100%', padding: '20px 0' }}>
                        {/* <h4 className="text-upper">{serviceFactory.Detail.FactoryProcess}</h4> */}
                        <div className="col-xs-12 col-sm-1 col-md-2 col-lg-2 hidden-xs hidden-sm"></div>
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                            <img src={content.lang == "th" ? ServiceFactoryFlowchart : ServiceFactoryFlowchart} alt="payonmarineservices" style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="row ourpartner" style={{ height: '100%', padding: '30px 0' }}>
                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-2"></div>
                        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-8 text-justify">
                            <p className="hidden-xs hidden-sm" style={{ fontSize: '20px' }}>{ourPartner.Detail.join(' ')}</p>
                            <p className="hidden-md hidden-lg">{ourPartner.Detail.join(' ')}</p>
                        </div>
                    </div>
                </div>
                <FabToTop tooltip={content.Text.Button.ToTop}></FabToTop>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        content: state.content
    }
}

const mapDispatchToProps = dispatch => {
    return {
        switchLanguage: (lang) => {
            return dispatch(switchLanguage(lang))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceFactory)