import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { browserHistory } from 'react-router'
import Home from '../app/Home'
import AboutUs from '../app/AboutUs'
import Service from '../service/Service'

import Footer from '../app/Footer'
import ScrollToTop from '../../utils/ScrollToTop'
import '../../css/App.css'
import Factory from '../home/ServiceFactory'
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import reducers from './../../reducers'

const middleware = applyMiddleware(thunk, logger)
const store = createStore(reducers, middleware)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <div>
            <Router history={browserHistory}>
              <div>
                <ScrollToTop>
                  <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
                  <Route path={`${process.env.PUBLIC_URL}/aboutus`} exact component={AboutUs} />
                  <Route path={`${process.env.PUBLIC_URL}/service`} exact component={Service} />
                  <Route path={`${process.env.PUBLIC_URL}/factory`} exact component={Factory} />
                </ScrollToTop>
              </div>
            </Router>
            <Footer />
          </div>
        </div>
      </Provider>
    );
  }
}

export default App;
