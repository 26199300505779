import React, { Component } from 'react'
import { LogoCompany, Iso9001, Iso14001, Iso } from '../../api/constants/ConfigImage'

export default class Contact extends Component {
    render() {
        const { name, companyName, address, tel, fax, email } = this.props
        return (
            <div id="contactus" style={{ paddingBottom: '0px', backgroundColor: '#5c5c5c' }} >
                <div className="row" style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <div className="section-title wow fadeInDown">
                        <h4 className="text-upper text-white">{name}</h4>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="row">
                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <div style={{ maxWidth: "200px", margin: "auto" }}>
                                            <a href="/"><img src={LogoCompany} alt="payonmarineservices" /></a>
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <div style={{ maxWidth: "230px", margin: "auto", padding: '25px 10px' }}>
                                            <a href="/"><img src={Iso} alt="payonmarineservices" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <p className="text-left wow fadeInDown "><b>{companyName}</b></p>
                                <div className="text-left wow fadeInDown ">
                                    {
                                        address.map((value, i) => {
                                            return <p key={i}><b>{value.Name}</b> : &nbsp;
                                            <span className="hidden-xs hidden-sm">{value.Detail}</span>
                                                <span className="hidden-md hidden-lg"><br />{value.Detail}</span></p>
                                        })
                                    }
                                </div>
                                <p className="text-left wow fadeInDown ">
                                    <b>Tel :</b> {tel},&nbsp;
                                    <b className="hidden-xs hidden-sm">Fax :</b> {fax}
                                    <b className="hidden-md hidden-lg"><br />Fax :</b> {fax}
                                </p>
                                <div className="text-left wow fadeInDown "> <b>Email : </b>
                                    {
                                        email.map((value, i) => {
                                            return <span key={i}>{value}{i == email.length - 1 ? '' : ', '}</span>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        {/* <div className="item quality-info hidden-md hidden-lg" style={{ border: "1px solid", height: '250px' }}>
                            </div>
                            <div className="item quality-info hidden-sm hidden-xs" style={{ border: "1px solid", height: '350px' }}>
                            </div> */}
                        <iframe style={{ border: 0 }}
                            width="100%"
                            height="500"
                            frameBorder="0"
                            allowFullScreen
                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB3eCWQZ8TiNVTtg28gHJlLWIi6PLes8JE&q=บริษัท+พยนต์มารีนเซอร์วิส+จำกัด&center=13.1236463,100.8994312" >
                        </iframe>
                    </div>
                </div>
            </div>
        );
    }
}