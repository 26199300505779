import React, { Component } from 'react'
import Reveal from 'react-reveal/Reveal'
import {
  LogoClientIRPC, LogoClientMOL, LogoClientMUL, LogoClientNYK, LogoClientPTT, LogoClientSC,
  LogoClientTHO, LogoClientTHORESEN, LogoClientUNI, LogoClientVan, LogoClientDeme,LogoClientHeung,
  LogoClientOocl, LogoClientLBH
} from '../../api/constants/ConfigImage'

export default class OurClient extends Component {
  render() {
    let images = [
      LogoClientIRPC, LogoClientMOL, LogoClientPTT, LogoClientTHO, LogoClientUNI, LogoClientDeme, 
      LogoClientVan, LogoClientHeung, LogoClientOocl, LogoClientLBH
    ];
    let tagImage = [];
    images.forEach((value, i) => {
      tagImage.push(
        <Reveal effect="fadeInUp" key={i} >
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3">
            <div className="ourclient" >
              <img src={value} alt="payonmarineclient" />
            </div>
          </div>
        </Reveal>)
    });

    const { name, detail } = this.props

    return (

      <div id="testimonial" className="ourclient-height ourclient-bg">
        <div className="container">
          <div className="section-header">
            {/* <div className="section-title text-center wow fadeInDown"><h3 style={{ color: 'black' }}>{name}</h3></div> */}
          </div>
          <div className="row">
            {tagImage}
          </div>
        </div>
      </div>
    );
  }
}