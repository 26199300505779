import React, { Component } from 'react'
import '../../css/menu.css'
import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap'
import { NavLink } from 'react-router-dom'
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { APP_Name } from '../../api/constants/ConfigImage'

export default class Menu extends Component {

  constructor(props) {
    super(props)
    this.state = { menuColor: '', headerColor: '', nameColor: 'text-white' }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    // let scrollTop = event.srcElement.body.scrollTop;
    let windowHeight = window.innerHeight;
    let windowWidth = window.innerWidth;

    if (window.location.pathname.indexOf('aboutus') > -1 || window.location.pathname.indexOf('service') > -1 || window.location.pathname.indexOf('factory') > -1) {
      this.setState({ menuColor: 'navbar-fixed-top menu-background-image' });
    } else if (windowWidth <= 768) {  // for iphone 4
      if (window.scrollY > windowHeight - 300) {
        this.setState({ menuColor: 'navbar-fixed-top menu-background-image' })
      }
      else {
        this.setState({ menuColor: 'navbar-fixed-top menu-background-color' })
      }

    } else {
      if (windowWidth >= 1024 && windowHeight >= 1366 && window.scrollY >= 500) { // for ipad pro
        this.setState({ menuColor: 'navbar-fixed-top menu-background-image' })
      } else if (windowWidth => 1024) {  // for web
        if (window.scrollY > windowHeight - 80) {
          this.setState({ menuColor: 'navbar-fixed-top menu-background-image' })
        }
        else {
          this.setState({ menuColor: 'navbar-fixed-top menu-background-color' })
        }

      }
    }
  }

  setHeaderBackground = (event) => {
    this.setState({ headerColor: 'border-collapse-unset' });

    // if (window.location.pathname.indexOf('aboutus') == -1) {
    //   if (event) {
    //     this.setState({ headerColor: 'border-collapse-unset', nameColor: 'text-white' });
    //   }
    // } else {
    //   if (event) {
    //     this.setState({ headerColor: 'border-collapse-unset' });
    //   }
    // }
  }

  render() {
    const data = this.props.data;
    let switchLanguage = this.props.switchLanguage;

    return (
      <header id="menu" className={this.state.menuColor}>
        <div className="hidden-md hidden-lg">
          <div className="row">
            <Navbar fluid collapseOnSelect onToggle={(e) => this.setHeaderBackground(e)}>
              <Navbar.Header className={this.state.headerColor}>
                <Navbar.Brand>
                  <a href="/"> <span className={this.state.nameColor}>{this.props.shortName}</span></a>
                </Navbar.Brand>
                <Navbar.Toggle />
              </Navbar.Header>
              <Navbar.Collapse className={this.state.headerColor}>
                <Nav pullRight>
                  {/* <IndexLinkContainer to="/" activeClassName="active-link">
                    <NavItem>{data.home}</NavItem>
                  </IndexLinkContainer> */}
                  <LinkContainer to="/aboutus" activeClassName="active-link" className={this.state.nameColor}>
                    <NavItem >{data.aboutus}</NavItem>
                  </LinkContainer>
                  {/* <LinkContainer to="/warehouse" activeClassName="active-link">
                    <NavItem>{data.warehouse}</NavItem>
                  </LinkContainer>*/}
                  <NavDropdown eventKey={4} title={data.services} id="basic-nav-dropdown">
                    <LinkContainer to="/service" activeClassName="active-link"><MenuItem eventKey={4.1}>{data.marine}/{data.onShore}</MenuItem></LinkContainer>
                    <LinkContainer to="/factory" activeClassName="active-link"><MenuItem eventKey={4.2}>{data.factory}</MenuItem></LinkContainer>
                  </NavDropdown>
                  <NavItem eventKey={5} href="/#contactus">{data.contact}</NavItem>
                  <LinkContainer to="/service" activeClassName="active-link">
                    <NavItem><NavLink id="lang-th" to="/" activeStyle={{ background: 'transparent' }} onClick={switchLanguage.bind(this, 'en')}>EN</NavLink>
                      |
                          <NavLink id="lang-en" to="/" activeStyle={{ background: 'transparent' }} onClick={switchLanguage.bind(this, 'th')}>TH</NavLink></NavItem>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
        <div className="hidden-xs hidden-sm">
          <div className="row show-grid" style={{ paddingTop: '10px' }}>
            <div className="col-sm-5 col-md-5 col-lg-6 name">
              <div id="head-text">
                <a href="/"> <span className={this.state.nameColor}>{this.props.name}</span></a>
                {/* <img src={APP_Name} alt="name" /> */}
              </div>
            </div>
            <div className="col-sm-7 col-md-7 col-lg-6 page-menu">
              {/* <div className="row">
                <div className="col-sm-offset-6 col-md-offset-6">
                  <div className="text-center head-contact">
                    <i className="text-success fa fa-phone fa-w-16"></i><label>&nbsp;(66) 3835-1950 &emsp;</label>
                    <i className="text-primary fa fa-map-marker fa-w-12"></i> <label>&nbsp;{data.province}&emsp;</label>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-12">
                  <Navbar fluid collapseOnSelect>
                    <Navbar.Header>
                      <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse>
                      <Nav pullRight>
                        {/* <IndexLinkContainer to="/" activeClassName="active-link">
                          <NavItem>{data.home}</NavItem>
                        </IndexLinkContainer> */}
                        <LinkContainer to="/aboutus" activeClassName="active-link">
                          <NavItem>{data.aboutus}</NavItem>
                        </LinkContainer>

                        {/* <LinkContainer to="/service" activeClassName="active-link">
                          <NavItem>{data.services}</NavItem>
                        </LinkContainer>
                        <LinkContainer to="/factory" activeClassName="active-link">
                          <NavItem>{data.factory}</NavItem>
                        </LinkContainer> */}
                        <NavDropdown eventKey={4} title={data.services} id="basic-nav-dropdown" activeClassName="active-link">
                          <LinkContainer to="/service" activeClassName="active-link"><MenuItem eventKey={4.1}>{data.marine}/{data.onShore}</MenuItem></LinkContainer>
                          <LinkContainer to="/factory" activeClassName="active-link"><MenuItem eventKey={4.2}>{data.factory}</MenuItem></LinkContainer>
                        </NavDropdown>
                        <NavItem eventKey={5} href="/#contactus">{data.contact}</NavItem>
                        <LinkContainer to="/service" activeClassName="active-link">
                          <NavItem><NavLink id="lang-th" to="/" activeStyle={{ background: 'transparent', color: '#FF' }} onClick={switchLanguage.bind(this, 'en')}>EN</NavLink>
                            |
                          <NavLink id="lang-en" to="/" activeStyle={{ background: 'transparent', color: '#FFF' }} onClick={switchLanguage.bind(this, 'th')}>TH</NavLink></NavItem>
                        </LinkContainer>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header >
    );
  }
}