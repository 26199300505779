import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import { Button } from "react-bootstrap";
import { ServiceArea } from "../../api/constants/ConfigImage";

export default class DetailInfo extends Component {
    render() {
        let tagsDetail = [];
        if (this.props.detail != null) {
            this.props.detail.map((item, index) => {
                tagsDetail.push(
                    <p key={index} className="text-justify" > {item} </p>
                );
            });
        }

        return (
            <div id="detailinfo" className="section-height" >
                <div className="row text-center" >
                    <Fade left >
                        <div className="col-sm-6 col-md-6 col-lg-6" >
                            <div className="panel-one text-middle-parent" >
                                <div className="text-center text-middle-child" >
                                    <h4 className="text-upper" > {this.props.name} </h4> {tagsDetail}
                                    <br />
                                    {/* <div className="readmore-button" >
                                        <Button variant="outline-secondary"> {this.props.message.Button.MoreDetail} </Button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="col-sm-6 col-md-6 col-lg-6" style={{ paddingTop: 20 }} >
                            <div className="item quality-info hidden-md hidden-lg" style={{ backgroundImage: "url(" + ServiceArea + ")", height: "250px" }} >
                            </div>
                            <div className="item quality-info hidden-sm hidden-xs" style={{ backgroundImage: "url(" + ServiceArea + ")", height: "350px" }} >
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
}