import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { connect } from 'react-redux'
import { switchLanguage } from '../../actions/content'
import Menu from '../app/Menu'
import FabToTop from '../app/FabToTop'
import '../../css/pricingtable.css'
import {
    ServiceHeader,
    ServiceHeaderTh,
    ServiceBoatTug,
    ServiceBoatBilge,
    ServiceBoatShuttle,
    ServiceVan,
    ServiceCar
} from '../../api/constants/ConfigImage'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Service extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const content = this.props.content.content;
        const contentService = this.props.content.content.page.Services.Detail;
        const marineService = contentService.MarineService;
        const onShoreService = contentService.OnShoreService;
        const marineServiceImage = [ServiceBoatTug, ServiceBoatBilge, ServiceBoatShuttle];
        const onShoreServiceImage = [ServiceVan, ServiceCar];
        const switchLanguage = this.props.switchLanguage;
        const marinePanel = [];
        marineService.Detail.map(function (value, i) {
            marinePanel.push(
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <div className="card">
                        <div class="card-header">
                            <LazyLoadImage
                                alt="payon marine services boat bilge"
                                effect="blur"
                                src={marineServiceImage[i]}
                                width="100%"
                            />

                            {/* <img src={marineServiceImage[i]} className="card-img-top" alt="payonmarineservices" style={{ width: '100%' }} /> */}
                        </div>
                        <div className="card-body">
                            <h5 class="card-title">{value.ServiceName}</h5>
                            <p className="card-text text-left">{value.Description}</p>
                        </div>
                    </div>
                </div>);
        })
        const onShorePanel = [];
        onShoreService.Detail.map(function (value, i) {
            onShorePanel.push(
                <div className="row" key={i}>
                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                        <LazyLoadImage
                            alt="payon marine services onshore"
                            effect="blur"
                            src={onShoreServiceImage[i]}
                            width="100%"
                        />
                        {/* <img src={onShoreServiceImage[i]} alt="payonmarineservices" style={{ width: '100%' }} /> */}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-8 col-lg-8">
                        <h4>{value.ServiceName}</h4>
                        <p className="text-left">{value.Description}</p>
                    </div>
                </div>
            );
        })

        return (
            <div style={{ marginBottom: '20px'}}>
                <Menu name={content.appName} shortName={content.appShortName} data={content.page.Menu} switchLanguage={switchLanguage} />
                <div id="service">
                    <div className="row head-info-height head-info">
                        <LazyLoadImage
                            alt="payon marine services"
                            effect="blur"
                            src={content.lang == "th" ? ServiceHeaderTh : ServiceHeader}
                            // width="100%"
                        />
                        {/* <img src={content.lang == "th" ? ServiceHeaderTh : ServiceHeader} alt="payonmarineservices" /> */}
                    </div>
                    <div className="row justify-content-around">
                        <div className="col-xs-12 col-sm-12 col-md-1 col-lg-1"></div>
                        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                            <h3>{marineService.Name}</h3>
                            <div className="row"> {marinePanel}</div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-1 col-lg-1"></div>
                        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                            <h3>{onShoreService.Name}</h3>
                            {onShorePanel}
                        </div>
                    </div>
                   
                </div>
                <FabToTop tooltip={content.Text.Button.ToTop}></FabToTop>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        content: state.content
    }
}

const mapDispatchToProps = dispatch => {
    return {
        switchLanguage: (lang) => {
            return dispatch(switchLanguage(lang))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Service)