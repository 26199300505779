import React, { Component } from 'react';
import Fade from 'react-reveal/Fade'
import { Example3, Example4 } from '../../api/constants/ConfigImage'

export default class EnvironmentCare extends Component {
    render() {
        var tagsDetail = [];
        if (this.props.detail != null) {
            this.props.detail.forEach(function (value, i) {
                tagsDetail.push(<p key={i} className="text-justify">{value}</p>)
            })
        }
        return (
            <div id="qualityproduct" className="section-height" style={{ backgroundColor: "#fff" }}>
                {/* #b2ebf2 */}
                <div className="row text-center">
                    <Fade left>
                        <div className="col-sm-6 hidden-md hidden-lg">
                            <div className="panel-one">
                                <div className="text-center">
                                    <h4 style={{ paddingTop: 20 }}>{this.props.name}</h4>
                                    {tagsDetail}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 hidden-sm hidden-xs" style={{ padding: '0 40px' }}>
                            <div className="item quality-info hidden-md hidden-lg" style={{ height: '250px', backgroundImage: "url(" + Example4 + ")" }}>
                            </div>
                            <div className="item quality-info hidden-sm hidden-xs" style={{ height: '350px', backgroundImage: "url(" + Example4 + ")" }}>
                            </div>
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="col-sm-6 col-md-6 col-lg-6 hidden-md hidden-lg" style={{ padding: '0 20px' }}>
                            <div className="item quality-info hidden-md hidden-lg" style={{ height: '250px', backgroundImage: "url(" + Example4 + ")" }}>
                            </div>
                            <div className="item quality-info hidden-sm hidden-xs" style={{ height: '350px', backgroundImage: "url(" + Example4 + ")" }}>
                            </div>
                        </div>
                        <div className="col-sm-6 hidden-sm hidden-xs">
                            <div className="panel-one text-middle-parent">
                                <div className="text-center text-middle-child">
                                    <h4 className="text-upper">{this.props.name}</h4>
                                    {tagsDetail}
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
}