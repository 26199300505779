import { Container, Button, Link } from 'react-floating-action-button'
import React, { Component } from 'react';

export default class FabToTop extends Component {
    render() {
        const tooltip = this.props.tooltip;

        return (
            <Container id="fab-to-top">
                <Button className="fab-item btn btn-link btn-lg text-white"
                    tooltip=""
                    icon="fa fa-chevron-up"
                    rotate={false}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'auto' })} >{tooltip}</Button>
            </Container>
        )
    }
}