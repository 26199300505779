import React, { Component } from 'react'
import Reveal from 'react-reveal/Reveal'
import Fade from 'react-reveal/Fade'
import { BilgeBoat, CarTransfer, Factory, BoatTransfer, TugBoat } from '../../api/constants/ConfigImage'

export default class ServiceInfo extends Component {
    render() {
        var name = this.props.name;
        var detail = this.props.detail;
        var textLabel = this.props.message;
        var images = [TugBoat, BilgeBoat, CarTransfer, Factory];
        var serviceInfo = []
        if (detail != null) {
            detail.forEach(function (value, i) {
                serviceInfo.push(
                    <Reveal effect="fadeInUp" key={i} >
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6" style={{ padding: '20px' }}>
                            <div className="serviceInfo"><img src={images[i]} alt="payonmarine" /></div>
                            <div className="text-center">
                                <h4 className="">{value}</h4>
                            </div>
                        </div>
                    </Reveal >)
            })
        }

        return (
            <div id="chooseus" className="section-height" style={{ height: 'unset' }}>
                <div className="row">
                    <div className="text-center">
                        <h4 className="text-upper" style={{ paddingTop: 20 }}>{name}</h4>
                    </div>
                    <div className="row" style={{ paddingTop: 20 }}>
                        {serviceInfo}
                    </div>
                </div>
            </div>
            // <div id="serviceInfo" className="section-height">
            //     <div className="row">
            //         <div className="text-center">
            //             <h4 className="text-upper" style={{ paddingTop: 20 }}>{this.props.name}</h4>
            //         </div>
            //         <div className="row" style={{ paddingTop: 20 }}>
            //             {serviceInfo}
            //         </div>
            //     </div>
            // </div>
        );
    }
}
