import React, { Component } from 'react';
import { connect } from 'react-redux'
import { switchLanguage } from '../../actions/content'
import Menu from '../app/Menu'
import Info from '../home/Info'
import DetailInfo from '../home/DetailInfo'
import OurClient from '../home/OurClient'
import QualityProduct from '../home/QualityProduct'
import WorkExperience from '../home/WorkExperience'
import EnvironmentCare from '../home/EnvironmentCare'
import ServiceInfo from '../home/ServiceInfo'
import Factory from '../home/ServiceFactory'
import ChooseUs from '../home/ChooseUs'
import Contact from '../contact/Contact'
import fetch from 'isomorphic-fetch'
import FabToTop from '../app/FabToTop'

class Home extends Component {
    render() {
        const content = this.props.content.content;
        const switchLanguage = this.props.switchLanguage;

        if (content) {
            return (
                <div id="home" className="homepage">
                    <Menu name={content.appName} shortName={content.appShortName} data={content.page.Menu} switchLanguage={switchLanguage} />
                    <Info name={content.page.Info.Name} detail={content.page.Info.Detail} />
                    <DetailInfo name={content.page.DetailInfo.Name} detail={content.page.DetailInfo.Detail} message={content.Text} />
                    <ServiceInfo name={content.page.Services.Name} detail={content.page.Services.Info} message={content.Text} />
                    <QualityProduct name={content.page.QualityProducts.Name} detail={content.page.QualityProducts.Detail} />
                    <ChooseUs name={content.page.ChooseUs.Name} detail={content.page.ChooseUs.Detail} moreDetail={content.page.ChooseUs.DetailMore} />
                    <WorkExperience name={content.page.WorkExperience.Name} detail={content.page.WorkExperience.Detail} message={content.Text} />
                    <OurClient name={content.page.OurClient.Name} detail={content.page.OurClient.Detail} />
                    <EnvironmentCare name={content.page.EnvironmentCare.Name} detail={content.page.EnvironmentCare.Detail} />
                    {/* <WareHouse name={content.page.WareHouse.Name} detail={content.page.WareHouse.Detail}/> */}
                    <Contact name={content.page.Contact.Name}
                        companyName={content.page.Contact.Detail.CompanyName}
                        address={content.page.Contact.Detail ? content.page.Contact.Detail.Address : []}
                        tel={content.page.Contact.Detail ? content.page.Contact.Detail.Tel : ""}
                        fax={content.page.Contact.Detail ? content.page.Contact.Detail.Fax : ""}
                        email={content.page.Contact.Detail ? content.page.Contact.Detail.Email : []}
                    />
                   <FabToTop tooltip={content.Text.Button.ToTop}></FabToTop>
                </div>
            );
        } else {
            return <div></div>
        }

    }
}

const mapStateToProps = state => {
    return {
        content: state.content
    }
}

const mapDispatchToProps = dispatch => {
    return {
        switchLanguage: (lang) => {
            return dispatch(switchLanguage(lang))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)