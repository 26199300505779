import * as action_types from './../api/constants/action-types'

const contentsEn = require('./../api/data-en.json');
const contentsTh = require('./../api/data-th.json');

let api = {
  getContent(language = 'en') {
    if(language == 'en'){
      return contentsEn.filter(obj => obj.lang === language)[0];
    }else{
      return contentsTh.filter(obj => obj.lang === language)[0];
    }
    
  }
};
const initialState = {
  content: api.getContent()
}

export const content = function (state = initialState, action) {
  switch (action.type) {
    case action_types.SWITCH_LANGUAGE:
      return {
        content: api.getContent(action.language)
      };
    default:
      return state;
  }
};

